<template>
  <div class="totals-data section-display">
    <h2>Total Resources Acquired</h2>
    <div class="totals-data__materials-wrapper">
      <Carousel :wrap-around="true" :breakpoints="breakpoints">
        <Slide
            v-for="(section, index) in data"
            :key="index"
        >
          <HorizontalBars
            v-if="index !== 'Bells'"
            :chart-data="section"
            :chart-title="index"
            :chart-options="options"
          />
        <div class="bells-display" v-else>
          <h3>{{ index }}</h3>
          <div class="bells-display-inner">
            <div class="bells-display-inner-content">
              <div class="icon">
                <img :src="getImageUrl(index)" :alt="index" />
              </div>
              <div class="counter">
                {{ prettyNumber(section.datasets[0].data[0]) }}
              </div>
            </div>
          </div>
        </div>

        </Slide>
        <template #addons>
          <Navigation/>
          <Pagination/>
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import useAxios from "@/api/UseAxios";
import {computed, onMounted, reactive, watch, toRefs, ref} from "vue";
import {useStore} from "vuex";
//import MaterialDisplay from "@/components/MaterialDisplay";
import HorizontalBars from "@/components/HorizontalBars";
import {Carousel, Navigation, Pagination, Slide} from 'vue3-carousel';


export default {
  name: "TotalMaterials",
  components: {
    //MaterialDisplay
    HorizontalBars,
    Carousel,
    Navigation,
    Pagination,
    Slide
  },
  setup() {
    const store = useStore();
    const start = computed(() => store.state.Dates.start);
    const end = computed(() => store.state.Dates.end);
    const params = computed(() => ({
      start_date: start.value,
      end_date: end.value,
      format: 'chart'
    }));
    const url = computed(() => `materials/totals`);
    const state = reactive({
      data: null,
      isLoading: true
    });

    const breakpoints = ref({
      1200: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
    });

    const options = ref({
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'y',
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false,
        },
        datalabels: {
          formatter: function(value) {
            return value.toLocaleString();
          },
          font: {
            weight: 'bold',
            size: 15,
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          }
        }
      }
    });

    const {data, isLoading, fetchData} = useAxios(url.value);

    const getTotalsData = async () => {
      state.isLoading = true;
      await fetchData(params.value);
      state.data = data;
      state.isLoading = isLoading.value
    };

    onMounted(() => {
      getTotalsData();
    });

    watch([start, end], () => {
      getTotalsData();
    });

    function getImageUrl(name) {
      const formattedName = name.replace(/\s+/g,'-').toLowerCase();
      const images = require.context('@/assets/materials-icons/', false, /\.png$/);
      return images('./' + formattedName + '.png');
    }

    function prettyNumber(number) {
      return number.toLocaleString();
    }

    return {
      ...toRefs(state),
      options,
      breakpoints,
      getImageUrl,
      prettyNumber
    }
  }

}
</script>

<style lang="scss" scoped>
.totals-data {

  .bells-display {
    color: #333;
    text-align: center;
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      margin: 0 auto;
    }

    .bells-display-inner {
      width: 100%;
      flex-grow: 1;

      .bells-display-inner-content {
        margin: auto 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
      }

      .icon img {
        width: 200px;
      }

      .counter {
        font-weight: bold;
        font-size: 1.6em;
        padding: .25em 0;
      }
    }

  }
}

</style>
