export const DatesModule = {
    namespaced: true,

    state: {
        earliest: null,
        latest: null,
        start: null,
        end: null,
    },

    mutations: {
        updateDates(state, dates) {
            state.earliest = dates.earliest;
            state.latest = dates.latest;
            state.start = dates.start;
            state.end = dates.end;
        }
    },

    actions: {
        setDates({ commit }, dates) {
            commit('updateDates', dates);
        }
    }
}
