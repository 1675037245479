<template>
  <div class="total-value section-display">
    <h2>Total Value of Resources</h2>
    <div class="bells-display" v-if="!isLoading">
      <div class="icon">
        <img :src="getImageUrl('bells')" alt="bell bag" />
      </div>
      <div class="counter">
        {{ prettyNumber(data.total_value) }} Bells
      </div>

    </div>
  </div>
</template>

<script>
import {computed, onMounted, reactive, watch, toRefs} from 'vue';
import {useStore} from "vuex";
import useAxios from "@/api/UseAxios";

export default {
  name: "TotalValue",
  setup() {
    const store = useStore();
    const start = computed(() => store.state.Dates.start);
    const end = computed(() => store.state.Dates.end);
    const params = computed(() => ({
      start_date: start.value,
      end_date: end.value
    }));
    const url = computed(() => `drops/totals/value`);
    const state = reactive({
      data: {},
      isLoading: true
    });

    const {data, isLoading, fetchData} = useAxios(url.value);

    const getTotalsValue = async () => {
      await fetchData(params.value)
      state.data = data;
      state.isLoading = isLoading.value
    }

    onMounted(() => {
      getTotalsValue();
    });

    watch([start, end], () => {
      getTotalsValue()
    });

    function getImageUrl(name) {
      const formattedName = name.replace(/\s+/g,'-').toLowerCase();
      const images = require.context('@/assets/materials-icons/', false, /\.png$/);
      return images('./' + formattedName + '.png');
    }

    function prettyNumber(number) {
      return number.toLocaleString();
    }

    return {
      ...toRefs(state),
      getImageUrl,
      prettyNumber
    }
  }
}
</script>

<style lang="scss" scoped>
.total-value {

  .bells-display {
    margin: 1rem;
    padding: 1rem;
    color: #333;
    text-align: center;

    h3 {
      margin: 0 auto;
    }

    .icon img {
      width: 200px;
    }

    .counter {
      font-weight: bold;
      font-size: 1.6em;
      padding: .25em 0;
    }

  }
}


</style>
