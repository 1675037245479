import {createStore} from 'vuex'
import {DatesModule} from "@/store/Dates";

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        Dates: DatesModule
    }
})
