<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  setup() {
    const title = process.env.VUE_APP_TITLE;

    return {
      title
    }
  }
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
