<template>
  <div class="sources-wrap section-display">
    <h2>Resources By Rock</h2>

    <StatsDisplay />

    <div class="sources-wrap__legend">
      <div class="sources-wrap__legend-item">
        <span class="colored-block stone"></span>
        <p>Stone</p>
      </div>
      <div class="sources-wrap__legend-item">
        <span class="colored-block iron-nugget"></span>
        <p>Iron Nugget</p>
      </div>
      <div class="sources-wrap__legend-item">
        <span class="colored-block clay"></span>
        <p>Clay</p>
      </div>
    </div>
    <div class="sources-wrap__charts-wrap">
      <Carousel :wrap-around="true" :breakpoints="breakpoints" v-if="!isLoading">
        <Slide
          v-for="(source, index) in charts.minerals"
          :key="index"
        >
          <DoughnutDisplay
              :chart-data="source.chart"
              :other-data="source.other"
              :chart-title="index"
          />
        </Slide>
        <template #addons>
          <Navigation/>
          <Pagination/>
        </template>
      </Carousel>
    </div>
    <div class="sources-wrap__charts-wrap" v-if="!isLoading">
      <Carousel :wrap-around="true">
        <Slide
          v-for="(chart, index) in charts.metals"
          :key="index">
          <HorizontalBars
              :chart-data="chart"
              :chart-title="index"
              :chart-options="vbar_options" />
        </Slide>
        <template #addons>
          <Navigation/>
          <Pagination/>
        </template>
      </Carousel>
    </div>


  </div>
</template>

<script>
import useAxios from "@/api/UseAxios";
import DoughnutDisplay from "@/components/DoughnutDisplay";
import {computed, reactive, watch, onMounted, ref} from 'vue'
import {toRefs} from "vue";
import {useStore} from "vuex";
import {Carousel, Navigation, Pagination, Slide} from 'vue3-carousel';
import HorizontalBars from "@/components/HorizontalBars";
import StatsDisplay from "@/components/StatsDisplay";

export default {
  name: "SourceCharts",
  components: {
    StatsDisplay,
    HorizontalBars,
    DoughnutDisplay,
    Carousel,
    Navigation,
    Pagination,
    Slide
  },
  setup() {
    const store = useStore();
    const start = computed(() => store.state.Dates.start);
    const end   = computed(() => store.state.Dates.end);
    const params = computed(() => ({
      start_date: start.value,
      end_date: end.value,
      format: 'chart',
      sources: [1,2,3,4,5,6]
    }));

    const url   = computed(() => `drops/totals/by-source`);
    const state = reactive({
      charts: null,
      isLoading: true
    });

    const { data, isLoading, fetchData } = useAxios(url.value);

    const getChartData = async () => {
      state.isLoading = true;
      await fetchData(params.value)
      state.charts = data;
      state.isLoading = isLoading.value
    }

    onMounted(() => {
      getChartData();
    });

    watch([start, end], () => {
      getChartData()
    });

    const breakpoints = ref({
      1000: {
        itemsToShow: 1.5,
        snapAlign: 'start',
      },
      1200: {
        itemsToShow: 2.5,
        snapAlign: 'start',
      },
      1500: {
        itemsToShow: 3,
        snapAlign: 'start',
      }
    });

    const vbar_options = ref({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false,
        },
        datalabels: {
          formatter: function(value) {
            return value.toLocaleString();
          },
          font: {
            weight: 'bold',
            size: 15,
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          }
        }
      }
    });

    return {
      ...toRefs(state),
      breakpoints,
      vbar_options
    }
  }
}
</script>

<style lang="scss" scoped>
.sources-wrap {

  .sources-wrap__legend {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 1rem;

    .sources-wrap__legend-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        margin-top: 0;
      }

      .colored-block {
        display: inline-block;
        width: 3rem;
        height: 1rem;
      }

      .black {
        background-color: #000;
      }

      .iron-nugget {
        background-color: #303030;
      }

      .clay {
        background-color: #c2a26c;
      }

      .stone {
        background-color: #a8a6a7;
      }
    }
  }
}

</style>
