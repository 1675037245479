<template>
  <div class="production-by-day section-display">
    <h2>Resources By Day</h2>
    <div class="production-by-day__charts_wrap">
      <Carousel :wrap-around="true" :breakpoints="breakpoints">
        <Slide
            v-for="(section, index) in data"
            :key="index"
        >
          <HorizontalBars
              :chart-data="section"
              :chart-title="index"
              :chart-options="getOptions(index)"
              wrap-class="tall"
          />
        </Slide>
        <template #addons>
          <Navigation/>
          <Pagination/>
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, reactive, ref, toRefs, watch} from "vue";
import {useStore} from "vuex";
import useAxios from "@/api/UseAxios";
import HorizontalBars from "@/components/HorizontalBars";
import {Carousel, Navigation, Pagination, Slide} from 'vue3-carousel';

export default {
  name: "MaterialsByDay",
  components: {
    HorizontalBars,
    Carousel,
    Navigation,
    Pagination,
    Slide
  },
  setup() {
    const store = useStore();
    const start = computed(() => store.state.Dates.start);
    const end = computed(() => store.state.Dates.end);
    const params = computed(() => ({
      start_date: start.value,
      end_date: end.value,
      format: 'chart',
      direction: 'horizontal',
      grouped: 1
    }));
    const url = computed(() => `drops/totals/by-day`);
    const state = reactive({
      data: {},
      isLoading: true
    });

    const {data, isLoading, fetchData} = useAxios(url.value);

    const getChartData = async () => {
      await fetchData(params.value)
      state.data = data;
      state.isLoading = isLoading.value
    }

    onMounted(() => {
      getChartData();
    });

    watch([start, end], () => {
      getChartData()
    });

    const breakpoints = ref({
      1200: {
        itemsToShow: 2,
        snapAlign: 'start',
      },
    });

    function getOptions(section) {
      options.plugins.datalabels.display = section === 'Bells';
      return options;
    }

    let options = {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'y',
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: false,
        },
        datalabels: {
          formatter: function (value) {
            return value.toLocaleString();
          },
          font: {
            weight: 'bold',
          },
          display: false
        },
      },
      scales: {
        x: {
          stacked: false,
          grid: {
            display: false,
          },
        },
        y: {
          stacked: false,
        },
      },
    };

    return {
      ...toRefs(state),
      breakpoints,
      getOptions
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
