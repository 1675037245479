<template>
  <div class="stats-wrap" v-if="!isLoading">
    <Carousel :wrap-around="true">
      <Slide key="hits">
        <div class="stats-section">
          <h3>Hitting Rocks</h3>
          <ul>
            <li>The rocks were successfully struck a total of <span class="bold">{{ prettyNumber(data.strikes.strikes) }} times</span></li>
            <li>On {{ data.strikes.gold_pct }}% of those hits, a rock produced a Gold Nugget</li>
            <li>There were a total of {{data.stingy.rocks}} instances over {{data.stingy.days}} days where a rock refused to give its full eight drops</li>
          </ul>
        </div>
      </Slide>
      <Slide key="minerals">
        <div class="stats-section">
          <h3>Minerals from Rocks</h3>
          <ul>
            <li>The total value of minerals produced from the rocks was <span class="bold">{{prettyNumber(data.minerals.total)}} bells</span></li>
            <li>The average value of minerals from each rock was <span class="bold">{{prettyNumber(data.minerals.average)}} bells</span></li>
            <li>Excluding bells, the most valuable rock was <span class="bold">{{data.minerals.max_rock}}</span>, producing <span class="bold">{{prettyNumber(data.minerals.max)}} bells</span> worth of minerals</li>
          </ul>
        </div>
      </Slide>
      <Slide key="bells">
        <div class="stats-section">
          <h3>Bells from Rocks</h3>
          <ul>
            <li>The average bells produced per rock was <span class="bold">{{ prettyNumber(data.bells.average) }}</span></li>
            <li><span class="bold">{{ data.bells.max_rock }}</span> produced the most bells yielding <span class="bold">{{ prettyNumber(data.bells.max_bells) }} bells</span> ({{ data.bells.max_pct_more }}% more than average).</li>
            <li><span class="bold">{{ data.bells.min_rock }}</span> produced the least bells yielding <span class="bold">{{ prettyNumber(data.bells.min_bells) }} bells</span> ({{ data.bells.min_pct_less }}% less than average).</li>
            <li>The bells rock yielded 26,000 bells on {{data.bells.katrina}} days, thanks to a fortune of improved luck with bells from Katrina</li>
          </ul>
        </div>
      </Slide>
      <template #addons>
        <Navigation/>
        <Pagination/>
      </template>
    </Carousel>
  </div>
</template>

<script>
import {computed, reactive, watch, onMounted, toRefs} from 'vue'
import {useStore} from "vuex";
import useAxios from "@/api/UseAxios";
import {Carousel, Navigation, Pagination, Slide} from 'vue3-carousel';

export default {
  name: "StatsDisplay",
  components: {
    Carousel,
    Navigation,
    Pagination,
    Slide
  },
  setup() {
    const store = useStore();
    const start = computed(() => store.state.Dates.start);
    const end = computed(() => store.state.Dates.end);
    const params = computed(() => ({
      start_date: start.value,
      end_date: end.value,
    }));

    const url = computed(() => `drops/stats`);
    const state = reactive({
      data: null,
      isLoading: true
    });

    const {data, isLoading, fetchData} = useAxios(url.value);

    const getStatsData = async () => {
      state.isLoading = true;
      await fetchData(params.value)
      state.data = data;
      state.isLoading = isLoading.value
    }

    onMounted(() => {
      getStatsData();
    });

    watch([start, end], () => {
      getStatsData()
    });

    function prettyNumber(number) {
      return number.toLocaleString();
    }

    return {
      ...toRefs(state),
      prettyNumber
    }
  }

  }
</script>

<style lang="scss" scoped>
.stats-wrap {
  .stats-section {
    h3 {
      text-align: center;
    }
    text-align: left;
  }
}
</style>
