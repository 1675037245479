<template>
  <div class="chart-wrap" :class="[wrapClass]">
    <h3>{{chartTitle}}</h3>
    <div class="chart-wrap__chart" :class="[wrapClass]">
      <BarChart :chartData="chartData" :options="chartOptions" />
    </div>
  </div>
</template>

<script>

import {BarChart} from 'vue-chart-3'

export default {
  name: "HorizontalBars",
  components: {
    BarChart
  },
  props: {
    chartData: {
      type: Object,
      default () {
        return {};
      }
    },
    chartTitle: {
      type: String,
      default: ''
    },
    chartOptions: {
      type: Object,
      default () {
        return {};
      }
    },
    wrapClass: {
      type: String,
      default: ''
    }
  },

}
</script>

<style lang="scss" scoped>
.chart-wrap {
  text-align: center;
  width: 90%;

  h3 {
    margin: 0 auto;
  }

  .chart-wrap__chart.tall > div{
    height: 200vh;
  }
  .chart-wrap__chart.wide > div{
    height: 50vh;
  }

}

.chart-wrap.tall {
  width: 100%;
}
</style>
