import {computed, reactive, toRefs} from "vue";
import axios from "axios";

export default function (endpoint) {
    const base_url = process.env.VUE_APP_API;

    const url = computed(() => `${base_url}${endpoint}`);

    const state= reactive({
        isLoading: true,
        isError: false,
        errorMessage: '',
        data: null
    });

    const fetchData = async (params = {}, method = 'get') => {
        try{
            const response = await axios({
                method: method,
                url: url.value,
                data: params,
                params: params
            });
            state.data = response.data;
        } catch(e) {
            state.isError = true;
            state.errorMessage = e.message;
        } finally {
            state.isLoading = false;
        }
    }

    return {
        ...toRefs(state),
        fetchData
    }
}
