import {createRouter, createWebHistory} from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(async (to, from, next) => {
    const dates = store.state.Dates;

    if (!dates.start) {
        const earliest = '2021-12-01';
        //const now = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        const latest = '2021-12-31';
        const defaultDates = {
            earliest: earliest,
            latest: latest,
            start: earliest,
            end: latest,
        };

        await store.dispatch('Dates/setDates', defaultDates);
    }

    next();

})

export default router
