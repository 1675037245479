<template>
  <div class="home">
    <h1>Slumbernot Resource Project</h1>
    <div class="introduction section-display">
      <p>Slumbernot is an <i>Animal Crossing: New Horizons</i> island founded in January of 2021. For this project, I tracked the resources produced from rocks and trees for the month of December, 2021.</p>
      <p>You can visit Slumbernot using Dream Address: <span class="bold">DA-0381-6187-5688</span></p>

      <p>To learn more about the project, use the link below, or continue on to view the data.</p>

      <div class="introduction__button-links">
        <a href="#data-start" class="btn">View the Data</a>
        <router-link to="/about" class="btn">About the Project</router-link>
      </div>
    </div>

    <div id="data-start">&nbsp;</div>
    <div class="date-display">
      <p class="bold">Showing Data For</p>
      <p>12/1/2021 - 12/31/2021</p>
    </div>
    <TotalValue />
    <TotalMaterials/>
    <SourceCharts />
    <MaterialsByDay />
  </div>
</template>

<script>

import TotalMaterials from "@/components/TotalMaterials";
import SourceCharts from "@/components/SourceCharts";
import MaterialsByDay from "@/components/MaterialsByDay";
import TotalValue from "@/components/TotalValue";

export default {
  name: 'Home',
  components: {
    TotalValue,
    MaterialsByDay,
    SourceCharts,
    TotalMaterials,
  }
}
</script>

<style lang="scss" scoped>
.home {
  padding: 10px 5%;

  h1 {
    font-weight: normal;
    font-size: 1.8em;
  }

  #data-start {
    width: 0;
    height: 0;
  }

  .date-display {
    width: 100%;
    text-align: center;
    font-size: 1.2em;

    p {
      margin: 0 auto;
    }
  }

  .introduction {
    .introduction__button-links {
      display: flex;
      flex-flow: row wrap;
      gap: 1rem;

      .hide-big {
        display: none;
      }
    }
  }

  @media (max-width: 32rem) {
    .introduction {
      min-height: 100vh;
      .introduction__button-links {
        .hide-big {
          display: block;
        }
      }
    }
  }

}
</style>
