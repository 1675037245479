<template>
  <div class="chart-wrap">
    <div class="chart-wrap__heading">{{chartTitle}}</div>
    <div class="chart-wrap__container">
      <DoughnutChart :chartData="chartData" :options="options" />
    </div>
    <div class="chart-wrap__other-items">
      <div class="other-item" v-for="(item, index) in otherData" :key="index">
        <div class="icon">
          <img :src="getImageUrl(index)" :alt="index" />
        </div>
        <div class="name">
          {{index}}: {{prettyNumber(item)}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DoughnutChart} from 'vue-chart-3';
import {ref} from 'vue';

export default {
  name: "DoughnutDisplay",
  components: {
    DoughnutChart
  },
  props: {
    chartData: {
      type: Object,
      default() {
        return {}
      }
    },
    otherData: {
      type: Object,
      default() {
        return {}
      }
    },
    chartTitle: {
      type: String,
      default: ''
    }
  },
  setup() {
    const options = ref({
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
        },
        datalabels: {
          color: 'white',
          font: {
            size: '18px',
            weight: 'bold',
          },
        },
      },
      hoverOffset: 6
    });

    function getImageUrl(name) {
      const formattedName = name.replace(/\s+/g,'-').toLowerCase();
      const images = require.context('@/assets/materials-icons/', false, /\.png$/);
      return images('./' + formattedName + '.png');
    }

    function prettyNumber(number) {
      return number.toLocaleString();
    }

    return {
      options,
      getImageUrl,
      prettyNumber
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-wrap {
  border: 1px solid #bbb;
  border-radius: 10px;
  margin: 1rem auto;
  padding: .5rem;
  width: 90%;

  .chart-wrap__heading {
    font-size: 1.1em;
    font-weight: bold;
    padding: .25em 0;
  }

  .chart-wrap__other-items {
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-top: 10px;


    .other-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 50%;

      .icon > img {
        width: 40px;
      }
    }
  }
}


</style>
